import indexStyles from '../sass/index.scss';
let commons = require('./utils/commons');
import mainSliderJS from './includes/main_slider.js';
import specialistsSliderJS from './includes/specialists_slider.js';
import newsSliderJS from './includes/news_slider.js';
import pricesSliderJS from './includes/prices_slider.js';
import customTabsJS from './includes/custom_tabs.js';
import singleImageSliderJS from './includes/single_image_slider.js';
import mainSlider1Img from '../img/main-slider-1.jpg';
import mainSliderShevronLeftImg from '../img/main-slider-shevron-left.png';
import index1Img from '../img/index-1.jpg';
import index2Img from '../img/index-2.jpg';
import threeDTourImg from '../img/3d-tour.png';
import companyPointsImg from '../img/company-points.png';
import childrenDentistryBkgImg from '../img/children-dentistry-bkg.png';
import sliderControllsLeftImg from '../img/slider-controlls-left.png';
import doctor1Img from '../img/doctor-1.png';
import doctor2Img from '../img/doctor-2.png';
import doctor3Img from '../img/doctor-3.png';
import doctor4Img from '../img/doctor-4.png';
import news1Img from '../img/news-1.jpg';
import news2Img from '../img/news-2.jpg';
import news3Img from '../img/news-3.jpg';
import news4Img from '../img/news-4.jpg';
import apointment1Img from '../img/apointment-1.jpg';
import footerPhoneIconImg from '../img/footer-phone-icon.png';
import footerMailIconImg from '../img/footer-mail-icon.png';
