function singleImageSlider(sliderNode) {
    const sliderContainer = sliderNode.getElementsByClassName('slider-viewport')[0];
    if (sliderContainer === undefined || !sliderContainer) {
        console.log('singleImageSlider: no sliderContainer found by class "slider-viewport"')
        return false;
    }
    const prevButtonContainer = sliderNode.getElementsByClassName('prev')[0];
    const nextButtonContainer = sliderNode.getElementsByClassName('next')[0];
    if (prevButtonContainer === undefined || !prevButtonContainer) {
        console.log('singleImageSlider: no prevButtonContainer found by class "prev"');
        return false;
    }
    if (nextButtonContainer === undefined || !nextButtonContainer) {
        console.log('singleImageSlider: no nextButtonContainer found by class "next"');
        return false;
    }
    const sliders = sliderContainer.getElementsByClassName('slide');

    $(sliderContainer).slick({
		slidesToShow: 1,
		slidesToScroll: 1,
        arrows: true,
        infinite: true,
        nextArrow: nextButtonContainer,
        prevArrow: prevButtonContainer,
        responsive: [

    	]
	});
}


document.addEventListener('DOMContentLoaded', function() {
    const singleImageSliderNodes = document.getElementsByClassName('single-image-slider');
    for(let i = 0; i < singleImageSliderNodes.length; i++) {
        let singleImageSliderNode = singleImageSliderNodes.item(i);
        const singleImageSliderObj = new singleImageSlider(singleImageSliderNode);
    }
});
